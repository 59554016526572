import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const HowToGetFollowersEnterprise = ({ location }) => {
  const title = "【今すぐ実践！】Twitter 企業アカウントでフォロワーを増やす方法"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【今すぐ実践！】Twitter 企業アカウントでフォロワーを増やす方法"
          pagedesc=" Twitterの企業アカウント運用で懸念されるのがフォロワー数の伸び悩みです。新規フォロワーを獲得できない、既存フォロワーが離れてしまう、何をすれば良いかわからないなど何かお困りであれば、一度こちらの記事をご覧ください。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-get-followers-enterprise.jpg"
          pagepath="/blog/how-to-get-followers-enterprise"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は10分で読むことができます
            </div>
            <h1>
              【今すぐ実践！】Twitter 企業アカウントでフォロワーを増やす方法
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年10月20日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="会議室で犬を見ている男女"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-get-followers-enterprise.jpg"
                className="w-full"
              />
            </figure>
            <p>
              企業アカウントでのフォロワー増加って難しいですよね。個人アカウントとは違い、企業イメージに関わるので、無闇に情報を発信する訳にはいきません。今回はフォロワーを増加させるメリットを交えながら、フォロワーを獲得するためのコツと注意点をご紹介します。
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    フォロワーを増やすメリット
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    フォロワーを獲得するコツ
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        1. プロフィールを充実させる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        2. ツイートを工夫する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        3. 画像や動画、GIFを付けて投稿をする
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        4.定期的にツイートする・ツイート数を増やす
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        5. フォロワーとコミュニケーションをとる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        6. プレゼントキャンペーンを実施する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        7. 分析を行う
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id10" className="toc__link">
                    Twitter利用の注意点
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id11" className="toc__link">
                    まとめ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">フォロワーを増やすメリット</h2>
              <p>
                「フォロワーが増える」こと自体にはメリットはありません。まずは「フォロワーが増えると何が良いのか」についてご説明します。こちらを踏まえTwitterを活用する目的を明確にしましょう。
              </p>
              <ul>
                <li>
                  無料で商品やサービスの認知拡大ができる
                  <p>
                    Twitterでは、広告費をかけずに自社や商品・サービスについてや周知したい情報を手軽かつリアルタイムで発信することができます。フォロワーが多ければ、ツイートをより沢山のユーザーに見ていただけるので、売上向上が期待できます。
                  </p>
                  <p>
                    また、フォロワー数＝拡散力とも言うことができます。フォロワーがリツイートやコメント等をしてくれれば、より情報を拡散することができます。拡散されることでフォロワー以外のユーザーにも認知され、新規フォロワーや顧客を獲得できる可能性が高まります。
                  </p>
                </li>
                <li>
                  信頼を得ることができる
                  <p>
                    「商品やサービスは魅力的に見えるが、本当だろうか」「怪しいサイトではないか」など不安を感じたことはないでしょうか。企業にとって大切なのは「信頼」です。Twitterは誰でも簡単に運営できるので、やはり怪しいアカウントも存在します。だからこそ、フォロワーの多さは商品やサービスの購入を検討している方の安心材料となります。
                  </p>
                  <p>
                    また、他ユーザーに対しても「きちんとした企業のアカウント」だと認識されやすくなります。次項ではフォロワー獲得のコツをご紹介しますが、信頼を得るためにも重要な内容です。ぜひご覧ください。
                  </p>
                </li>
              </ul>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id2">フォロワーを獲得するコツ</h2>
            </div>
            <div>
              <h3 id="id3">1. プロフィールを充実させる</h3>
              <p>
                プロフィールでは名前、アイコン・ヘッダー画像、自己紹介文、場所、ウェブサイトを設定することができます。前述した「信頼」に関して、プロフィールでどれだけ簡潔に分かりやすく自社をアピールできるかが肝心です。ツイートに好感を持ったユーザーが訪れたとしても、プロフィールが無ければ、ツイートの説得力が失われます。自社の活動やツイートの目的が確実に伝わるような設定のポイントをご紹介します。
              </p>
              <ul className="blog__border-list">
                <li>
                  アカウントの名前
                  <p>アカウント名には「名前」と「ユーザー名」があります。</p>
                  <p>
                    「名前」はそのアカウントの呼び名やニックネームで、Twitter内で活動する際はこの名前が表示されます。こちらは会社名や商品・サービス名が無難ですが、会社名＋商品(サービス)名、商品(サービス)名＋商品(サービス)の説明でも良いでしょう。
                  </p>
                  <p>
                    注意として1〜50文字の文字制限内があります。また、ツイートやDM上では省略されて表示されるので、伝えたい内容は前半に書くことおすすめします。空白や絵文字、記号を駆使して一目でわかる名前にしましょう。
                  </p>
                  <p>
                    「ユーザー名」は@「ユーザー名」は@から始まるIDのようなものです。文字数は5〜15文字で、英数字と_のみ使用可能です。また、「Twitter」や「admin」、既存のユーザー名は使用できません。
                  </p>
                  <p>
                    こちらは主に検索やユーザーを特定する際に使われます。あまり注目されることはありませんが、こちらも自社に関係のあるワードを含めると良いでしょう。
                  </p>
                </li>
                <li>
                  アイコン・ヘッダー画像
                  <p>
                    アイコンとは円形の画像で、Twitter上の様々な場面で表示されるので言わばアカウントの「顔」となります。だからこそご会社や商品・サービスのロゴなど特徴的で特定しやすいものにしましょう。また、円形に表示されるので、ロゴ全体が入るように画像のサイズを調整しましょう。
                  </p>
                  <p>
                    ヘッダー画像とはプロフィールの背景画像です。カバー写真とも呼ばれます。アイコンに加え、自社がどんな企業なのか視覚的に瞬時に印象付けることができるのでとても重要です。一番アピールしたいポイントを画像として設定しましょう。
                  </p>
                  <p>
                    サイズは1500×500pxと横長なので、画像の主となる部分が切れないようご注意ください。
                  </p>
                </li>
                <li>
                  自己紹介文
                  <p>
                    自社や商品・サービスについて書きましょう。画像と自己紹介が合わさることで、より明確な印象を残すことができます。他にはツイートの意図や会社の特長、経歴などを記載することで親近感や信頼感、説得力を持たせることができます。
                  </p>
                  <p>
                    注意点として最大文字数が160文字となっているので、簡潔に伝えたいことだけを書きましょう。また読みやすさも大切なので、改行や絵文字等を使って工夫しましょう。
                  </p>
                </li>
                <li>
                  場所
                  <p>
                    会社の所在地やを記載しましょう。場所については記載がなくても問題ありませんが、ある方がより信頼感が増します。
                  </p>
                </li>
                <li>
                  ウェブサイト
                  <p>
                    Twitter以外のSNSやホームページ、LP、ECサイト等があれば、URLを貼り付けましょう。特にLPやECサイトがある場合は必ず掲載しましょう。わざわざ検索はしない程度の関心度でもURLがあれば、遷移率は向上します。
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <h3 id="id4">2. ツイートを工夫する</h3>
              <p>
                ユーザーやフォロワーが企業アカウントに求める内容は「有益な情報」です。多くのユーザーに見てもらい、新規フォロワー獲得かつ既存フォロワー離れを防止するために、ツイート内容については次を意識しましょう。
              </p>
              <ul className="blog__border-list">
                <li>
                  企業情報
                  <p>
                    プロフィールに書ききれなかった情報や、強調したい情報を発信すると良いでしょう。
                  </p>
                </li>
                <li>
                  新商品・サービス、新機能などのPR
                  <p>
                    ユーザーやフォロワーに最も需要のある情報です。Twitterのメリットはリアルタイムで拡散できることなので、何か新しい情報があれば積極的にツイートしましょう。
                  </p>
                </li>
                <li>
                  ハッシュタグをつけて投稿する
                  <p>
                    特定の目的をもったユーザーに向け発信できる、ツイートの拡散性が上がるというメリットがあります。
                  </p>
                </li>
                <li>
                  見栄えを意識する
                  <p>
                    いくら有益な情報でも読みにくいツイートはスクロールされてしまう傾向にあります。余白や絵文字等を適度に使いましょう。
                  </p>
                </li>
                <li>
                  ポシティブな内容を発信する
                  <p>
                    親近感を感じてもらうために、自社に関する内容だけでなく、SNS担当者についてや挨拶ツイートを投稿する方法もあります。せっかく読んでくれたユーザーに暗い気持ちを抱かせないように、明るい話題を発信しましょう。
                  </p>
                  <p>
                    また、内容に好感を持ったフォロワーやユーザーにリツイートしてもらえる可能性があります。「類は友を呼ぶ」というように、リツイートしたユーザーを見ている人々はそのユーザーと興味が似ている可能性が高いので、より効果的にフォロワーを増やすことができるでしょう。
                  </p>
                  <p>
                    一点、注意点としてツイート本文の文字数は最大140文字までとなっています。発信したい内容は簡潔にまとめましょう。
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <h3 id="id5">3. 画像や動画、GIFを付けて投稿をする</h3>
              <p>
                より多くのユーザーの目に留まるにはインパクトやツイートの第一印象が大切です。内容の他に画像をつけてツイートを印象的にするといった工夫もできます。画像や動画、GIFをつけることで画面に表示される面積が大きくなり、ユーザーの目に入る可能性が高まります。
              </p>
              <p>
                また、文字のみよりも画像がある方が情報が伝わりやすくなります。特に重要なツイートにはアピールポイントを押さえた画像を添付することを強くおすすめします。
              </p>
            </div>
            <div>
              <h3 id="id6">4. 定期的にツイートする・ツイート数を増やす</h3>
              <p>
                最終ツイートが古かったり、ツイート数が少ないとユーザーにフォローしても意味がないと判断されてしまいます。また、ツイート数が多ければそれだけユーザーの目に入る機会も多くなります。ただし数を打てば良いという訳ではないので、発信内容は精査しましょう。
              </p>
              <p>
                頻度や投稿時間としては朝、昼、夕方〜夜が良いと言われています。その理由として朝は通勤・通学時間、昼は休憩時間、夕方〜夜は通勤・通学に加え、帰宅しSNSを閲覧する人が多いからです。定期的に発信することで、既存フォロワーの記憶に定着し、他ユーザーの目にも留まりやすくなり新規フォロワー獲得に繋がります。
              </p>
            </div>
            <div>
              <h3 id="id7">5. フォロワーとコミュニケーションをとる </h3>
              <p>
                フォロワーを獲得し、フォロワー離れを防止する方法として、ユーザーとのコミュニケーションがあります。自社のツイートへのコメントや＠付きツイート、リツイートされた際に反応を示すことで、自社や商品・サービスに親近感を抱いてもらえます。そして反応があると単純に嬉しいものです。ということで、次の内容をおすすめします。
              </p>
              <ul>
                <li>
                  挨拶ツイートを投稿する
                  <p>ポジティブな内容を心がけましょう。</p>
                </li>
                <li>
                  自社ツイートのコメントに返信する
                  <p>
                    言葉遣いや内容は考えなくてはいけませんが、簡単でかまいません。
                  </p>
                </li>
                <li>
                  リプライや引用リツイートされたら、いいねやコメントをする
                </li>
                <li>
                  フォロワーの自社に関する投稿をリプライや引用リツイートする
                </li>
              </ul>
              <p>
                しかし、莫大な数のコメントやツイート全てに反応を返すのは時間と労力を要します。どこまでのコミュニケーションを実施するかルールを定めると良いでしょう。
              </p>
            </div>
            <div>
              <h3 id="id8">6. プレゼントキャンペーンを実施する</h3>
              <p>
                前項のコミュニケーションに関連して、プレゼントキャンペーンもご紹介します。プレゼントキャンペーンはフォロー・リツイートが参加条件である場合が多く、ユーザーが楽しめる企画なので拡散力が非常に高いことが特長です。また、既存フォロワー離れ防止、新規フォロワー獲得、会社や商品・サービスのPRに強い効果を発揮します。Twitterで開催可能なキャンペーンについて、下記をご参照ください。
              </p>
              <ul>
                <li>
                  <a
                    href="/case-study/twitter/instant-win-follow-and-retweet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    フォロー＆リツイートキャンペーン
                  </a>
                  <p>フォローとリツイートで応募できるキャンペーンです。</p>
                  <p>
                    最もポピュラーなキャンペーンで参加者が集まりやすいのが特長です。
                  </p>
                </li>
                <li>
                  <a
                    href="/case-study/twitter/instant-win-follow-and-retweet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    インスタントウィンキャンペーン
                  </a>
                  <p>
                    応募したその場で当落結果がわかる仕組みのキャンペーンです。
                  </p>
                  <p>参加のモチベーションを上げることができます。</p>
                </li>
                <li>
                  <a
                    href="/product/store-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    QRコードキャンペーン
                  </a>
                  <p>QRコード読み込みで参加できるWEB抽選キャンペーンです。</p>
                  <p>実店舗のある企業におすすめです。</p>
                </li>
                <li>
                  <a
                    href="https://scratch.park-lot.com/?_gl=1%2a173xdwp%2a_ga%2aNTExOTY5NzAwLjE2NTIwNzcwNjA.%2a_ga_LCFPLPR8R1%2aMTY2NDk1MzQwMi40NS4wLjE2NjQ5NTM0MDIuMC4wLjA."
                    target="_blank"
                    rel="noreferrer"
                  >
                    スクラッチキャンペーン
                  </a>
                  <p>
                    抽選結果確認としてスクラッチをけずることができるキャンペーンです。
                  </p>
                  <p>ゲーム感覚で、よりキャンペーンを楽しむことができます。</p>
                </li>
                <li>
                  <a
                    href="/product/coupon-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    クーポン配布キャンペーン
                  </a>
                  <p>参加者にクーポンを配布できるキャンペーンです。</p>
                  <p>実店舗やECサイトを持つ企業におすすめです。</p>
                </li>
                <li>
                  <a
                    href="/case-study/twitter/non-instant-win-follow-and-reply"
                    target="_blank"
                    rel="noreferrer"
                  >
                    フォロー＆リプライキャンペーン
                  </a>
                  <p>
                    対象ツイートにリプライ（返信）してもらうキャンペーンです。
                  </p>
                  <p>
                    コミュニケーションを取りながら、口コミやレビューを集めることができます。
                  </p>
                </li>
                <li>
                  <a
                    href="/case-study/twitter/follow-and-quote-tweet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    フォロー＆ハッシュタグ引用リツイートキャンペーン
                  </a>
                  <p>
                    オリジナルのハッシュタグをつけて引用ツイートしてもらうキャンペーンです。
                  </p>
                  <p>
                    盛り上がりが可視化しやすく、認知度が高まりやすいキャンペーンです。
                  </p>
                </li>
                <li>
                  <a
                    href="/case-study/twitter/non-instant-win-follow-and-retweet-or-like"
                    target="_blank"
                    rel="noreferrer"
                  >
                    リツイートorいいねキャンペーン
                  </a>
                  <p>
                    「リツイート」または「いいね」の好きなほうに応募・投票できるキャンペーンです。
                  </p>
                </li>
                <li>
                  カンバセーションボタン
                  <p>
                    最大4つのハッシュタグから好きなハッシュタグを選び、ツイートしてもらうキャンペーンです。
                  </p>
                </li>
                <li>
                  コンテンツ自動DM配布キャンペーン
                  <p>
                    資料などのコンテンツをDMにて配布できるキャンペーンです。該当ツイートをリツイートした人全員にファイルやURLをDM送信できます。
                  </p>
                </li>
              </ul>
              <p>
                商品やサービスの特性、目的に合わせTwitterにてプレゼントキャンペーンを開催されてみてはいかがでしょうか。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/risks-of-campaign-implementation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プレゼントキャンペーンを社内で実施する際に生じるリスクとその回避方法
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h3 id="id9">7. 分析を行う</h3>
              <p>
                Twitterには<strong>「ツイートアクティビティ」</strong>
                という機能があり、あなたの何のツイートを何人が見たか、興味を持ったかを数字で確認することができます。エンゲージメント・インプレッションの数値を読みとり、ツイート内容や時間帯などの改善策を考えましょう。
              </p>
              <ul>
                <li>
                  エンゲージメント
                  <p>
                    フォロー数、クリック数、リツイート数、いいね数、返信数といったツイートに対する反応です。
                  </p>
                  <p>
                    エンゲージメント率は [エンゲージメント数] ÷
                    [インプレッション数] ×
                    100で算出することができます。エンゲージメント率が高いほど、需要のあるツイートだということになります。
                  </p>
                </li>
                <li>
                  インプレッション
                  <p>
                    ユーザーの目に入った回数のことです。ユーザーの画面上に表示された回数なので、読まれたのか、読まれずスクロールされたのかまでは分かりません。
                  </p>
                  <p>
                    リツイートされることでインプレッション数を伸ばすことができます。
                  </p>
                </li>
              </ul>
              <p>
                参考：
                <a
                  href="https://help.twitter.com/ja/managing-your-account/using-the-tweet-activity-dashboard#:~:text=%E3%83%84%E3%82%A4%E3%83%BC%E3%83%88%E3%82%92%E3%82%BF%E3%83%83%E3%83%97%E3%81%97%E3%81%A6,%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%A7%E7%A2%BA%E8%AA%8D%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%80%82"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitterアカウントのアクティビティアナリティクス –
                  エンゲージメント、インプレッションなど
                </a>
              </p>
            </div>
            <div>
              <h2 id="id10">Twitter利用の注意点</h2>
              <p>
                Twitterを利用する上で、注意すべきルールがあります。場合によってはペナルティとしてアカウントを凍結される可能性があるので、確実に押さえておきましょう。具体的には以下の通りです。
              </p>
              <ol>
                <li>
                  過激な内容
                  <p>
                    過度な暴力的内容、性的内容、自傷行為など他ユーザーを不快にさせるの投稿は禁止です。
                  </p>
                </li>
                <li>
                  誹謗中傷や脅迫、個人情報の流出
                  <p>
                    ヘイト行為、脅迫めいた発言、本人の許可のない個人情報の流出も禁止です。
                  </p>
                </li>
                <li>
                  フォロー数の制限
                  <p>
                    スパムなどの迷惑行為を防止するため、1日にフォローできる数は最大400アカウント、認証Twitterアカウントの場合は最大1,000アカウントまでと決められています。
                  </p>
                </li>
              </ol>
              <p>
                特に2に関しては個人アカウントでももちろん禁止ですが、企業アカウントであれば尚更です。ユーザーや顧客にはそれぞれの価値観があることを理解し、責任をもって個人情報を管理しましょう。
              </p>
              <p>
                <br />
                1〜3の項目に関して、詳しくはTwitter公式サイトをご覧ください。
              </p>
              <p>
                参考：
                <a
                  href="https://help.twitter.com/ja/rules-and-policies/twitter-rules"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitterルール: セキュリティ、プライバシー、信頼性、その他
                </a>
                <br />
                　　　
                <a
                  href="https://help.twitter.com/ja/using-twitter/twitter-follow-limit"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitterのフォローリミットおよび比率とは？| Twitterヘルプ
                </a>
              </p>
              <p>
                <br />
                他にも
              </p>
              <ul>
                <li>誤解を招く発言をしない</li>
                <li>差別的な発言をしない</li>
                <li>触れる話題や内容が適切かどうか考える</li>
                <li>ツイート内容の間違いは早急に訂正する</li>
              </ul>
              <p>といったルールや炎上対策を考えることも重要です。</p>
              <p>
                Twitter運用に関しては社内でルールを定めることをおすすめします。
              </p>
            </div>
            <div>
              <h2 id="id11">まとめ</h2>
              <p>
                Twitterは、特に高い拡散力が特長のSNSです。Twitterを上手く活用することで大きな宣伝効果を発揮します。Twitter運用がただの負担とならないように、まずは目的やルールを明確にしましょう。そしてコツと注意点を押さえ、ユーザーを主体に考えた情報を発信していきましょう。
              </p>
              <p>
                <br />
                PARKLoTでは今回ご紹介したキャンペーンに対応しております。
              </p>
              <div className="blog__border-highlight">
                <ul>
                  <li>
                    <a
                      href="/case-study/twitter/instant-win-follow-and-retweet"
                      target="_blank"
                      rel="noreferrer"
                    >
                      インスタントウィンキャンペーン
                    </a>
                  </li>
                  <li>
                    <a
                      href="/case-study/twitter/twitter-non-instant-win"
                      target="_blank"
                      rel="noreferrer"
                    >
                      フォロー＆リツイート
                    </a>
                  </li>
                  <li>
                    <a
                      href="/case-study/twitter/non-instant-win-follow-and-reply"
                      target="_blank"
                      rel="noreferrer"
                    >
                      フォロー＆リプライキャンペーン
                    </a>
                  </li>
                  <li>
                    <a
                      href="/case-study/twitter/follow-and-quote-tweet"
                      target="_blank"
                      rel="noreferrer"
                    >
                      フォロー＆ハッシュタグ引用リツイートキャンペーン
                    </a>
                  </li>
                  <li>
                    <a
                      href="/product/hashtag-campaign"
                      target="_blank"
                      rel="noreferrer"
                    >
                      UGC（ハッシュタグ）
                    </a>
                  </li>
                  <li>
                    <a
                      href="/product/coupon-campaign"
                      target="_blank"
                      rel="noreferrer"
                    >
                      クーポン配布
                    </a>
                  </li>
                  <li>
                    <a
                      href="/product/store-campaign"
                      target="_blank"
                      rel="noreferrer"
                    >
                      QRコードキャンペーン
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://scratch.park-lot.com/?_gl=1%2a173xdwp%2a_ga%2aNTExOTY5NzAwLjE2NTIwNzcwNjA.%2a_ga_LCFPLPR8R1%2aMTY2NDk1MzQwMi40NS4wLjE2NjQ5NTM0MDIuMC4wLjA."
                      target="_blank"
                      rel="noreferrer"
                    >
                      スクラッチキャンペーン
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="kakefuda" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/fan-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プロが教えるTwitterインスタントウィン・キャンペーンのやり方。売上アップに効果的なのは「ファンマーケティング」
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/2022-fan-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【2022年版】Twitter
                    企業アカウント運用のコツと注意点（ファンマーケティング）
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign-basic"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterプレゼント企画のやり方【まずはこれだけ！テンプレ付】基本編
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-campaign-banner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【実例20選】効果の出やすいTwitterキャンペーンバナー！現役デザイナーが徹底解説！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default HowToGetFollowersEnterprise
